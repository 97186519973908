import logo from './assets/logo.svg';
import styles from './App.module.scss';
 import axios from "axios";
import { useEffect,useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import Zoom from 'react-medium-image-zoom'
import parse from 'html-react-parser';

import "./sidebar.css";
function App() {
function nodeScriptReplace(node) {
        if ( nodeScriptIs(node) === true ) {
                node.parentNode.replaceChild( nodeScriptClone(node) , node );
        }
        else {
                var i = -1, children = node.childNodes;
                while ( ++i < children.length ) {
                      nodeScriptReplace( children[i] );
                }
        }

        return node;
}
function nodeScriptClone(node){
        var script  = document.createElement("script");
        script.text = node.innerHTML;

        var i = -1, attrs = node.attributes, attr;
        while ( ++i < attrs.length ) {                                    
              script.setAttribute( (attr = attrs[i]).name, attr.value );
        }
        return script;
}

function nodeScriptIs(node) {
        return node.tagName === 'SCRIPT';
}
    
 const [cmsPages, setcmsPages] = useState([]);
 const [currPage, setcurrPage] = useState('Load');
 const [currHtml, setcurrHTML] = useState('<b>Laden paginas..</b>');
 const [isOpen, setOpen] = useState(false)
   const handleIsOpen = () => {
   	execallscripts();
    setOpen(!isOpen)
  }

  const closeSideBar = () => {
    setOpen(false)
  }
function retrievenumpage(numpage) {
	    const response = axios.get('./json.php?Page='+numpage)
        .then(function (response) {
     // handle success
        console.log(response.data);
        const mainpage = JSON.parse(JSON.stringify(response.data));
        var newhtml = mainpage.html.replace(/\n/,"")
        setcurrHTML(newhtml);
        
        })
        .catch(function (error) {
        // handle error
	
        console.log(error);
         })
}
function retrievemainpage() {
	    const response = axios.get('./json.php?Page=1')
        .then(function (response) {
     // handle success
        console.log(response.data);
        const mainpage = JSON.parse(JSON.stringify(response.data));
        var newhtml = mainpage.html.replace(/\n/,"")
        setcurrHTML(newhtml);
        
        })
        .catch(function (error) {
        // handle error
	
        console.log(error);
         })
}
function retrievepages() {
	    const response = axios.get('./json.php')
        .then(function (response) {
     // handle success
        console.log(response.data);
        setcmsPages(JSON.parse(JSON.stringify(response.data)));
        setcurrPage('Home');
        getPageParams(); 
        })
        .catch(function (error) {
        // handle error
	
        console.log(error);
         })
	}
	
  const execallscripts = () => {
 
		var r = document.getElementsByTagName('script');
var allscripts = "";
var scriptb = document.createElement("script");
scriptb.id = "newscript";
let done = false;
for (var i = (r.length-1); i >= 0; i--) {

    if(r[i].getAttribute('id') != 'newscript'){
     allscripts = allscripts + r[i].innerHTML;
        r[i].parentNode.removeChild(r[i]);
       done = true;
    }

}
if (done == true) {
	scriptb.innerHTML = allscripts;
	document.head.appendChild(scriptb);

	done = false;
	}
	return;
	}
  const handleGetPages = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
	//retrievepages();
  };
const pages = cmsPages.filter(title => title !== 'News').map(item =>
  <div style={{overflow:'hidden'}}> 
        <a href='#' style={{textAlign:'center', display:'inline-block', padding:'5px', backgroundColor:'black', borderRadius: '3px', width:'200px', marginTop:'2px',textDecoration: 'none',fontWeight: 'bold', color: 'white'}} onClick={() => {setCurrPage(item.title,item.html); setOpen(!isOpen); }} >
             {item.title}
          </a>
     </div>
);
         
        
     if (currPage == 'Load') { retrievemainpage(); retrievepages(); }
     

  const getPageParams = () => {
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const page = urlParams.get('Page');
if (parseInt(page) >= 0) { 
retrievenumpage(page);
}
}

  function setCurrPage(title, html) {
    setcurrPage(title);
    var newhtml = html.replace(/\n/,"")
    setcurrHTML(newhtml);

  }
 
const CustomMenuIcon = () => <img src="./menu.svg" />;
    return (
        <div className={styles.App}>
	<div id="imglogo" style={{display:'block', float:'right', position:'fixed', width: '100vw', backgroundColor:'black', height: '79px', right:'0px', top:'0px', left:'0px'}}><img src="./brenlogotransp.png" style={{width: '200px', lineHeight:'99px', height:'auto', float:'right', marginTop:'4px', marginRight:'15px'}}></img></div> 
            <Menu  isOpen={isOpen}
    onOpen={handleIsOpen}
    onClose={handleIsOpen}
    style={{ position: 'fixed', height: '80px', lineHeight:'80px', overflow: 'hidden', verticalAlign: 'middle'}}>
    <img src="./brenlogo-menu.png" style={{width: '210px', height:'auto'}}></img><br />
				{pages}
				<br />
				
				
			</Menu>
            <header className={styles['App-header']}>
               
                {currPage == "menu" &&
                <div style={{minHeight:'100vh',fontSize:'22px', width:'100vw', textAlign:'center'}}>
                    {pages}
                </div>
                }
                  {currPage != "menu" &&
                  
                <div>
                    
                        <div style={{ minHeight:'70vh',fontSize:'15px', textAlign: 'left', width: '80vw', marginTop:'100px' }}><br />
                        {parse(currHtml)}
                        <br /><br />
                        </div>
       

                </div>

                  }
       
            </header>
            	<div id="footer" style={{display:'block', float:'right', position:'fixed', width: '100vw', backgroundColor:'black', color:'white', lineHeight:'30px', height: '30px', right:'0px', bottom:'0px', left:'0px'}}><b style={{display:'inline-block', float:'left', marginLeft:'15px', textDecoration:'none', fontWeight:'normal', color:'white'}}>&nbsp;&#9743;&nbsp;0512-518680</b><b style={{display:'inline-block', float:'right', marginRight:'15px', textDecoration:'none', fontWeight:'normal', color:'white'}}>&#9993;&nbsp;info@brendrachten.nl</b></div> 

        </div>
    );
}

export default App;
